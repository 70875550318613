<template>
    <div>
        <div class="addBtn">
            <el-button @click="locscai = true">添加</el-button>
            <el-button @click="renovate">刷新</el-button>
        </div>
    <!-- 内容区域pc端 -->
    <div class="tab">
        <el-card>
          <el-table
          ref="multipleTable"
          :data="parData"
          tooltip-effect="dark"
          style="width: 100%"
          height='500'
          highlight-current-row
          :header-cell-style = "{background:'#df6124'}">
          <el-table-column
          prop="device_id"
          label="采集器ID"
          width="200">
          </el-table-column>
          <el-table-column
          prop="devIMEI"
          label="采集器通讯模组IMEI号"
          width="170">
          </el-table-column>
          <el-table-column
          prop="devICCID"
          label="采集器SIM卡卡号"
          width="200">
          </el-table-column>
          <el-table-column
          prop="devSV"
          label="采集器程序版本号"
          width="150">
          </el-table-column>
          <el-table-column
          prop="devHV"
          label="采集器硬件版本号"
          width="150"
          :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column
          prop="devCSQ"
          label="当前信号强度"
          width="120">
          </el-table-column>
          <el-table-column
          prop="devTime"
          label="采集器当前时间"
          width="180">
          </el-table-column>
          <el-table-column
          prop="devInfo"
          label="采集器工作状态描述"
          :show-overflow-tooltip="true"
          width="200">
          </el-table-column>
          <el-table-column
          prop="plotReceiveTime"
          label="上报服务器时间"
          width="180">
          </el-table-column>
          <el-table-column
        fixed="right"
        label="操作"
        width="170">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="coledele(scope.row)"
            >
            删除
          </el-button>
        </template>
      </el-table-column>
      </el-table>
        </el-card>
      </div>
    <!-- 添加弹框 -->
    <el-dialog
title="添加信息"
:visible.sync="locscai"
width="20%">
<el-form>
  <el-form-item label="采集器ID: " :label-width="formLabelWidth" :required="true">
    <el-input v-model="teiajea.device_id" autocomplete="off"></el-input>
  </el-form-item>
</el-form>
<span slot="footer" class="dialog-footer">
  <el-button type="primary" @click="locscabtn">确 定</el-button>
</span>
    </el-dialog>
    </div>
</template>
<script>
import{ collectorget, collectordele, collectorpost, collectorCommandget}from'../../api/colleor'
import Cookies from 'js-cookie'
export default {
    data() {
        return {
            parData:[],// 获取采集器数据
            locscai: false, // 添加采集器
            formLabelWidth: '100px',
            famlis:{
                token:null
            },
            colldele:{// 删除
                token:null,
                device_id:null
            },
            teiajea:{//添加
              token: null,
              device_id: null
            },
            tableData:[],
        }
    },
    created(){
        this.colleData()
    },
    methods:{
      renovate(){
        this.colleData()
      },
        // 获取采集器信息
        colleData(){
            this.famlis.token = Cookies.get('token')
            collectorget(this.famlis).then((res)=>{
              console.log(res)
                this.parData = res.data.data
            })
        },
        // 添加采集器
        locscabtn(){
          this.teiajea.token = Cookies.get('token')
          collectorpost(this.teiajea).then((res) => {
            if(res.data.status === 'success'){
              this.$message.success(res.data.message)
              this.colleData()
              this.teiajea.device_id = null
              this.locscai = this.false
            } else {
              this.$message.error(res.data.message)
            }
          })
        },
        // 命令历史
        xiugai(row){
          console.log(row)
          this.historyVsibles = true
          this.watefamls.token = Cookies.get('token')
          this.watefamls.device_id = row.device_id
          collectorCommandget(this.watefamls).then((res) => {
            this.tableData = res.data.data
          })
        },
        // 删除采集器信息
        coledele(row){
            this.colldele.token = Cookies.get('token')
            this.colldele.device_id = row.device_id
            this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
            collectordele(this.colldele).then((res)=>{
                if (res.data.status === 'success') {
                    this.$message.success(res.data.message)
                    this.colleData()
          } else if (res.data.status === 'error') { this.$message.error(res.data.message) }
            })
        }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
        },
    }
  }
</script>

<style lang="less" scoped>
.addBtn{
    .el-button{
        background-color: #df6124;  
        color: #FFF;
        margin-bottom: 10px;
    }
}
// <!-- table表格 -->
.tab{
          // 分页
          .el-pagination{
            ::v-deep .el-pagination__rightwrapper{
              float: left;
              margin-left: 20px;
            }
          }
          .el-card{
            border-radius: 10px;
            ::v-deep .el-card__body{
              padding: 0px;
            }
            ::v-deep .el-table thead{
              color: #fff;
              font-weight: 300;
            }
           ::v-deep .cell{
             font-family:myFont;
            }
          }
          ::v-deep .cell{
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
            text-align: center;
          }
          .el-button{
            padding: 10px;
            border: 1px solid #df6124;
            background-color: #df6124;
            color: #FFF;
            letter-spacing: 2PX;
          }
  }
  
// 滚动条大小设置
::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  /*纵向滚动条*/
  width: 5px;
  /*横向滚动条*/
  height: 15px;
}
  // 单挑添加
  .el-dialog{
    .el-button{
      border: 1px solid #df6124;
      background-color: #df6124;
    }
  }
</style>