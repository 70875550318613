import request from "../utils/request";
// 获取采集器信息
export function collectorget (data) {
    return request({
      method: 'get',
      url: 'collector',
      params: data
    })
  }

  // 删除采集器信息
export function collectordele (data) {
    return request({
      method: 'delete',
      url: 'collector',
      data
    })
  }

  // 添加采集器信息
  export function collectorpost (data) {
    return request({
      method: 'post',
      url: 'collector',
      data
    })
  }

// 获取采集器命令历史
export function collectorCommandget (data) {
  return request({
    method: 'get',
    url: 'collectorCommand',
    params: data
  })
}